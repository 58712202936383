const PropTypes = require('prop-types');

const { camelCaseObject } = require('../build-utils/helpers/fieldRenaming');
const { shouldRedirect } = require('./externalRedirect');
const { PRODUCT_MAP_2U } = require('./discoveryConstants');

const getLevelType = levelTypes => {
  if (!levelTypes?.length > 0) {
    return '';
  }

  return levelTypes[0];
};

const processProgramSearchResult = (searchResultProgram, index, queryID) => {
  const camelCasedResult = camelCaseObject(searchResultProgram);
  const courseTitles = camelCasedResult.courseTitles ? camelCasedResult.courseTitles : [];
  const position = queryID ? index + 1 : undefined;
  const topics = camelCasedResult.tags
    ? camelCasedResult.tags.map(topic => ({ topic }))
    : camelCasedResult.tags;

  return {
    authoringOrganizations: camelCasedResult.owners,
    allowedIn: camelCasedResult.allowedIn,
    blockedIn: camelCasedResult.blockedIn,
    cardImageUrl: camelCasedResult.cardImageUrl,
    cardType: 'program',
    courses: courseTitles.map((title) => {
      const courseObject = {};
      courseObject.title = title;
      return courseObject;
    }),
    subscriptionEligible: camelCasedResult.subscriptionEligible,
    inProspectus: camelCasedResult.programType[0] !== 'masters',
    marketingPath: (new URL(camelCasedResult.marketingUrl)).pathname,
    title: camelCasedResult.title,
    type: camelCasedResult.programType[0],
    uuid: camelCasedResult.uuid,
    recentEnrollmentCount: camelCasedResult.recentEnrollmentCount,
    // 2u degree
    is2UDegree: camelCasedResult.is2UDegree,
    queryID,
    objectID: `program-${camelCasedResult.uuid}`,
    position,
    topics,
    productSource: {
      slug: camelCasedResult.productSource,
    },
    levelType: getLevelType(camelCasedResult.level),
  };
};

const process2UDegreeSearchResult = (searchResult2UDegree, index, queryID) => {
  const camelCasedResult = camelCaseObject(searchResult2UDegree);
  const position = queryID ? index + 1 : undefined;

  // We lowercase Doctorate degree programTypes to reduce duplication in the product card component.
  let productLine = PRODUCT_MAP_2U[camelCasedResult.programType[0].toUpperCase()].NAME;

  if (productLine === 'Doctorate') {
    productLine = productLine.toLowerCase();
  }

  return {
    title: camelCasedResult.title,
    partner: camelCasedResult.partner[0], // currently each 2U course only has a single partner
    image: {
      src: camelCasedResult.cardImageUrl,
    },
    logoFilename: camelCasedResult.owners[0].logoImageUrl,
    productLine,
    cardType: productLine,
    type: productLine,
    marketingURL: camelCasedResult.marketingUrl,
    queryID,
    objectID: `degree-${camelCasedResult.uuid}`,
    position,
    prospectusPath: (new URL(camelCasedResult.marketingUrl)).pathname,
    uuid: camelCasedResult.uuid,
    organizationShortCodeOverride: camelCasedResult.organizationShortCodeOverride,
    organizationLogoOverrideUrl: camelCasedResult.organizationLogoOverride,
    inProspectus: !shouldRedirect(camelCasedResult.uuid),
    productSource: {
      slug: camelCasedResult.productSource,
    },
    additionalMetadata: {
      externalUrl: camelCasedResult.externalUrl,
    },
    is2UDegreeProgram: true,
    levelType: getLevelType(camelCasedResult.level),
  };
};

const processCourseSearchResult = (searchResultCourse, index, queryID) => {
  const camelCasedResult = camelCaseObject(searchResultCourse);
  const position = queryID ? index + 1 : undefined;

  // Start Experiment WS-1768
  // Algolia tags are array of strings, Disco props expect array of object with topic field
  const topics = camelCasedResult.tags
    ? camelCasedResult.tags.map(topic => ({ topic }))
    : camelCasedResult.tags;
  // End Experiment WS-1768

  return {
    activeCourseRun: {
      key: camelCasedResult.activeRunKey,
      type: camelCasedResult.activeRunType,
      marketingUrl: camelCasedResult.marketingUrl,
      minEffort: camelCasedResult.minEffort,
      maxEffort: camelCasedResult.maxEffort,
      weeksToComplete: camelCasedResult.weeksToComplete,
    },
    allowedIn: camelCasedResult.allowedIn,
    blockedIn: camelCasedResult.blockedIn,
    cardType: 'course',
    courseType: 'course',
    image: {
      src: camelCasedResult.cardImageUrl,
    },
    inProspectus: true,
    owners: camelCasedResult.owners,
    organizationLogoOverrideUrl: camelCasedResult.organizationLogoOverride,
    prospectusPath: (new URL(camelCasedResult.marketingUrl)).pathname,
    title: camelCasedResult.title,
    uuid: camelCasedResult.uuid,
    recentEnrollmentCount: camelCasedResult.recentEnrollmentCount,
    queryID,
    objectID: `course-${camelCasedResult.uuid}`,
    position,
    // Start Experiment WS-1768
    topics,
    // End Experiment WS-1768
    productSource: {
      slug: camelCasedResult.productSource,
    },
    levelType: getLevelType(camelCasedResult.level),
  };
};

const process2USearchResult = (searchResultProduct, index, queryID, productMap) => {
  const camelCasedResult = camelCaseObject(searchResultProduct);
  const position = queryID ? index + 1 : undefined;

  return {
    title: camelCasedResult.title,
    partner: camelCasedResult.partner[0], // currently each 2U course only has a single partner
    image: {
      src: camelCasedResult.cardImageUrl,
    },
    logoFilename: camelCasedResult.owners[0].logoImageUrl,
    productLine: productMap.NAME,
    cardType: productMap.DISCOVERY_KEY,
    courseType: productMap.DISCOVERY_KEY,
    marketingURL: camelCasedResult.marketingUrl,
    queryID,
    objectID: `course-${camelCasedResult.uuid}`,
    position,
    prospectusPath: (new URL(camelCasedResult.marketingUrl)).pathname,
    uuid: camelCasedResult.uuid,
    organizationShortCodeOverride: camelCasedResult.organizationShortCodeOverride,
    organizationLogoOverrideUrl: camelCasedResult.organizationLogoOverride,
    inProspectus: !shouldRedirect(camelCasedResult.uuid),
    productSource: {
      slug: camelCasedResult.productSource,
    },
    additionalMetadata: {
      externalUrl: camelCasedResult.externalUrl,
    },
    levelType: getLevelType(camelCasedResult.level),
  };
};

const processExecEdSearchResult = (searchResultProduct, index, queryID) => (
  process2USearchResult(
    searchResultProduct,
    index,
    queryID,
    PRODUCT_MAP_2U.EXECUTIVE_EDUCATION,
  )
);

const processBootcampSearchResult = (searchResultProduct, index, queryID) => (
  process2USearchResult(searchResultProduct, index, queryID, PRODUCT_MAP_2U.BOOT_CAMP)
);

const getResultsByPopularity = results => [...results].sort((
  { recentEnrollmentCount: a },
  { recentEnrollmentCount: b },
) => {
  if (a > b) {
    return -1;
  } if (a < b) {
    return 1;
  }
  return 0;
});

const parseFacetsFromResults = ({ disjunctiveFacets }) => {
  // fromEntries would be cleaner but it breaks on IE11
  const facetCounts = {};
  disjunctiveFacets.forEach(({ name, data }) => { facetCounts[name] = data; });

  return facetCounts;
};

const parseRawSearchResults = ({ _rawResults }) => {
  const processFunctionMap = {
    Course: processCourseSearchResult,
    Program: processProgramSearchResult,
    'Executive Education': processExecEdSearchResult,
    '2U Degree': process2UDegreeSearchResult,
    'Boot Camp': processBootcampSearchResult,
  };

  const {
    hits,
    nbHits: total,
    nbPages: maxAvailablePages,
    page,
    queryID,
  } = _rawResults[0];

  const results = hits.map(
    (hit, index) => (
      processFunctionMap[hit.product](hit, index, queryID)
    ),
  );

  return {
    items: results,
    total,
    pages: maxAvailablePages,
    // currentPage off by one due to Algolia pagination starts with 0
    currentPage: page + 1 || 1,
  };
};

const limitedUniqueByUUIDObjectsArrayJoin = (parentArray, childArray, maxResults) => {
  const coursesCount = parentArray?.length || 0;
  if (coursesCount < maxResults) {
    const filteredArray = childArray?.filter(obj => !parentArray?.some(({ uuid }) => uuid === obj.uuid)) || [];
    const limit = Math.abs(coursesCount - maxResults);
    const truncatedArray = filteredArray.slice(0, limit);
    return parentArray?.concat(truncatedArray) || [];
  }
  return parentArray;
};

const programFields = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  authoringOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      logoImageUrl: PropTypes.string,
    }),
  ),
  cardImageUrl: PropTypes.string,
  marketingPath: PropTypes.string,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      course: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  ),
  type: PropTypes.string,
  inProspectus: PropTypes.bool,
  inYearValue: PropTypes.shape({
    perLeadUsa: PropTypes.number,
    perLeadInternational: PropTypes.number,
    perClickUsa: PropTypes.number,
    perClickInternational: PropTypes.number,
  }),
  levelType: PropTypes.string,
};

const courseFields = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  inProspectus: PropTypes.bool,
  prospectusPath: PropTypes.string,
  activeCourseRun: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    marketingUrl: PropTypes.string,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
  }),
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      logoImageUrl: PropTypes.string,
    }),
  ),
  inYearValue: PropTypes.shape({
    perLeadUsa: PropTypes.number,
    perLeadInternational: PropTypes.number,
    perClickUsa: PropTypes.number,
    perClickInternational: PropTypes.number,
  }),
  levelType: PropTypes.string,
};

const product2UShape = PropTypes.shape({
  title: PropTypes.string,
  partner: PropTypes.string,
  logoFilename: PropTypes.string,
  image: PropTypes.string,
  productLine: PropTypes.string,
  url: PropTypes.string,
  levelType: PropTypes.string,
});

module.exports = {
  product2UShape,
  processProgramSearchResult,
  processCourseSearchResult,
  processExecEdSearchResult,
  processBootcampSearchResult,
  process2UDegreeSearchResult,
  getResultsByPopularity,
  parseFacetsFromResults,
  parseRawSearchResults,
  programFields,
  courseFields,
  limitedUniqueByUUIDObjectsArrayJoin,
};
